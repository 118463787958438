var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"auth-wrapper auth-wrapper-login auth-v1 px-2"},[_c('div',{staticClass:"auth-inner py-2"},[_c('b-card',{staticClass:"mb-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('b-img',{staticStyle:{"width":"150px"},attrs:{"src":_vm.logoUrl,"alt":"Payrite"}})],1),_c('b-card-text',{staticClass:"mb-2 text-center"},[_vm._v(" Log in met je e-mailadres en wachtwoord ")]),_c('validation-observer',{ref:"loginForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-3",on:{"submit":function($event){$event.preventDefault();return _vm.login.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label-for":"email","label":"E-mailadres"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","name":"login-email","state":errors.length > 0 ? false:null,"placeholder":"john@example.com","autofocus":""},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"name":"login-password","placeholder":"Password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('transition',{attrs:{"name":"fade"}},[(_vm.showTfa)?_c('b-form-group',{directives:[{name:"show",rawName:"v-show",value:(_vm.showTfa),expression:"showTfa"}],attrs:{"label":"Two-Factor Authenticatie Code","label-for":"tfa"}},[_c('validation-provider',{attrs:{"name":"tfa","vid":"tfa","rules":"required|integer|digits:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"tfa","state":errors.length > 0 ? false:null,"type":"text","name":"tfa","placeholder":"123 456"},model:{value:(_vm.tfa),callback:function ($$v) {_vm.tfa=$$v},expression:"tfa"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e()],1),_c('b-alert',{directives:[{name:"height-fade",rawName:"v-height-fade.appear",modifiers:{"appear":true}}],staticClass:"mb-1",attrs:{"show":_vm.showLoginError,"variant":"danger"}},[_c('div',{staticClass:"alert-body"},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"InfoIcon"}}),_vm._v(" Vul een valide gebruikersnaam, wachtwoord en eventuele TFA code in. ")],1)]),_c('b-button',{staticClass:"mt-2",attrs:{"variant":"primary","type":"submit","block":"","disabled":invalid}},[_vm._v(" Inloggen ")])],1)]}}])})],1)],1)]),[_c('p',{staticClass:"clearfix mb-0 login-footer"})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }